export const RELEVANT_ORDER_ORDER_STATUSES = [
  'CATERING_DECLINED',
  'CATERING_PLACED',
  'CATERING_SUCCESSFUL',
  'INSERT_SUCCESSFUL',
  'PAYMENT_SUCCESSFUL',
  'REFUND_ERROR',
  'REFUND_REQUESTED',
  'REFUND_SUCCESSFUL',
  'UPDATE_SUCCESSFUL',
  'VALIDATION_ERROR',
];

export const RELEVANT_ORDER_PAYMENT_STATUSES = [
  'AUTHORIZATION_SUCCESS',
  'CAPTURE_SUCCESSFUL',
  'CANCEL_ERROR',
  'CANCEL_REQUESTED',
  'CANCEL_SUCCESSFUL',
  'PAYMENT_SUCCESSFUL',
  'REFUND_ERROR',
  'REFUND_REQUESTED',
  'REFUND_SUCCESSFUL',
  'VOID_ERROR',
  'VOID_REQUESTED',
  'VOID_SUCCESSFUL',
];

export const FULL_REFUND_DELIVERY_STATUSES = ['ORDER_CANCELLED'];

export const PAYMENT_VERSION_MAPPING = {
  V0: 0,
  V1: 1,
};

export const REFUND_ORDER_STATUSES = ['REFUND_REQUESTED', 'REFUND_SUCCESSFUL'];

export const REFUND_PAYMENT_STATUSES = [
  'REFUND_ERROR',
  'REFUND_REQUESTED',
  'REFUND_SUCCESSFUL',
  'VOID_SUCCESSFUL',
];

export const SECONDARY_IDENTIFIER_TYPES = {
  LOYALTY_12_DIGIT_SWIPE_CARD: 'LOYALTY_12_DIGIT_SWIPE_CARD',
  PAN_TOKEN: 'PAN_TOKEN',
  PHONE_NUMBER: 'PHONE_NUMBER',
};

export const AUTO_FULL_REFUND_LIMIT = 0;

export const AUTO_PARTIAL_REFUND_LIMIT = 1;

export const DEFAULT_LIMIT_FOR_ORDERS = 100;

export const OFFERS_VERSIONS = {
  V1: 'v1',
  V2: 'v2',
};

export const NO_PRODUCT_ID = 'NO_PRODUCT_ID';

export const ENVIRONMENT = process.env.NODE_ENV;

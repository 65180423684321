import React, { FC } from 'react';

import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

const cache = createIntlCache();

// Setting to en-US until we figure out how we want to handle internationalization
export const intl = createIntl(
  {
    locale: 'en-US',
    messages: {},
  },
  cache,
);

export const IntlProvider: FC = ({ children }) => (
  <RawIntlProvider value={intl}>{children}</RawIntlProvider>
);

import bkConfigs from './bk-configs';
import { IBrandConfigs } from './brand-config';

/*
 * The idea behind this file is to keep all brand driven logic
 * together in one place so that we're not constantly pulling in
 * Brand and BRAND from utils/constants in order to conditionally
 * render different areas of the app
 */

const brandConfigs: IBrandConfigs = bkConfigs;

export default brandConfigs;

import gql from 'graphql-tag';

export const CTGLoyaltyUserFragment = gql`
  fragment CTGLoyaltyUserFragment on CTGLoyaltyUser {
    id
    points
    createdAt
    email
    name
    dateOfBirth
    cognitoId
    partners {
      createdAt
      updatedAt
      id
      name
      partner
      partnerUserId
      status
      __typename
    }
  }
`;

import gql from 'graphql-tag';

import { CartEntryFragment } from './fragments';

export const FetchOrder = gql`
  query Order($rbiOrderId: String!) {
    Order(rbiOrderId: $rbiOrderId) {
      id: rbiOrderId
      __typename
      cart {
        cartEntries {
          ...CartEntryFragment
          children {
            ...CartEntryFragment
            children {
              ...CartEntryFragment
              children {
                ...CartEntryFragment
                children {
                  ...CartEntryFragment
                  children {
                    ...CartEntryFragment
                  }
                }
              }
            }
          }
        }
        discounts {
          name
          value
        }
        donations {
          id
          name
          totalCents
        }
        feCountryCode
        payment {
          cardType
          ccLast4
          fdCorrelationId
        }
        platform
        serviceMode
        storeAddress {
          addressLine1
          addressLine2
          city
          country
          state
        }
        storeDetails {
          timezone
        }
        storeId
        subTotalCents
        taxCents
        totalCents
        userId #  currently required for logging refund events to mParticle
      }
      createdAt
      customer {
        id: customerId
        email
        customerId
        name
        fdCustomerId
        isoCountryCode
      }
      delivery {
        dropoff {
          beforeTime
        }
        feeCents
        feeDiscountCents
        status
        tipCents
        cancellation {
          reason
          category
        }
        deliveryImageUrl
      }
      loyaltyTransaction {
        pointsEarned
        pointsBalance
        pointsUsed
        bonusPointsEarned
        rewardsUsed
      }
      refund {
        adminUserEmail
        approvedAmount
        primaryReason
        refundedItems {
          bkpn
          name
          parentName
          parentSanityId
          remediationId
          sanityId
        }
        secondaryReason
        subtotal
        tax
        transactionDateTime
      }
      revisions {
        createdAt
        cart {
          storeId
          storePosId
          subTotalCents
          subTotalPaidCents
          taxCents
          taxPaidCents
          ticketNumber
          totalCents
          totalPaidCents
          cartEntries {
            ...CartEntryFragment
            children {
              ...CartEntryFragment
              children {
                ...CartEntryFragment
                children {
                  ...CartEntryFragment
                  children {
                    ...CartEntryFragment
                    children {
                      ...CartEntryFragment
                    }
                  }
                }
              }
            }
          }
        }
        paymentStatus
        posOrderId
        posVendor
        revision
        status
        updatedAt
      }
      remediations {
        amount
        created
        displayName
        remediationReason
        remediationType
        remediationComments
        supportEmail
        transactionId
        transactionType
        userId
      }
      paymentStatus
      paymentVersion
      rbiOrderId
      status
      userId
    }
  }
  ${CartEntryFragment}
`;

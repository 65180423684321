import gql from 'graphql-tag';

export const CTGLoyaltyQuests = gql`
  query ctgLoyaltyQuestsByLoyaltyId($loyaltyId: String!) {
    ctgLoyaltyQuestsByLoyaltyId(loyaltyId: $loyaltyId) {
      id
      endDate
      incentiveType
      name
      startDate
      steps {
        id
        minimumSpend
        cartRequirements {
          id
          quantity
          name
        }
      }
    }
  }
`;

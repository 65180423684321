import gql from 'graphql-tag';

export const CartEntryFragment = gql`
  fragment CartEntryFragment on CartEntries {
    name
    pickerSelections
    price
    quantity
    sanityId
    type
    url
    productHierarchy {
      L1
      L2
      L3
      L4
      L5
    }
  }
`;

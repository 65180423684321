import React, { FC } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const CrownIcon: FC = () => {
  return (
    <SvgIcon style={{ paddingTop: '.15em' }} viewBox="0 0 20 18">
      <svg fill="none">
        <path
          d="M18.2474 1.46393L16.7193 2.52014L15.1912 3.57635C14.96 3.73366 14.6903 3.79786 14.4303 3.76576C14.1703 3.73687 13.9199 3.61487 13.7304 3.40941L12.3693 1.92622L11.0081 0.443031C10.7384 0.147677 10.3692 0 10 0C9.63081 0 9.26161 0.147677 8.99194 0.443031L7.63075 1.92622L6.26955 3.40941C6.08014 3.61487 5.82973 3.73687 5.56969 3.76576C5.30965 3.79465 5.03998 3.73366 4.80884 3.57635L3.2807 2.52014L1.75257 1.46393C1.3609 1.19426 0.898611 1.21352 0.548681 1.42219C0.198751 1.63087 -0.0420278 2.02253 0.00612773 2.49767L0.449157 6.89587L0.89219 11.2941C0.921083 11.5798 1.05592 11.8302 1.25496 12.01C1.454 12.1898 1.71725 12.2989 2.00619 12.2989H10H17.9938C18.2795 12.2989 18.546 12.1898 18.745 12.01C18.9441 11.8302 19.0789 11.5798 19.1078 11.2941L19.5508 6.89587L19.9939 2.49767C20.042 2.02253 19.8013 1.63087 19.4513 1.42219C19.0982 1.21352 18.6391 1.19426 18.2474 1.46393Z"
          fill="rgba(0, 0, 0, 0.54)"
        />
      </svg>
    </SvgIcon>
  );
};

export default CrownIcon;

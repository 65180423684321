import gql from 'graphql-tag';

/**
 * @param orderId optional order ID
 */
export const CTGLoyaltyIssuePoints = gql`
  mutation ctgLoyaltyIssuePoints(
    $loyaltyId: String!
    $loyaltyUserCognitoId: String!
    $points: Int!
    $reason: String!
    $refundedItems: [RefundedItemsInput]
    $orderId: String
    $comments: String
  ) {
    ctgLoyaltyIssuePoints(
      loyaltyId: $loyaltyId
      loyaltyUserCognitoId: $loyaltyUserCognitoId
      points: $points
      reason: $reason
      refundedItems: $refundedItems
      orderId: $orderId
      comments: $comments
    )
  }
`;

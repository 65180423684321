import { ListAlt, LocalMall, People, Redeem, Stars, Store, LocalOffer } from '@material-ui/icons';

import BKLogo from 'components/logos/bk';
import { BK_BBQ_BROWN } from 'state/theme/colors';

import CrownIcon from '../components/common/icons/crown';
import { routes } from '../utils/routing';
import { IBrandConfigs } from './brand-config';

const bkConfigs: IBrandConfigs = {
  links: [
    {
      name: 'Customers',
      route: routes.customer,
      Icon: People,
    },
    {
      name: 'Orders',
      route: routes.orders,
      Icon: LocalMall,
    },
    {
      name: 'Support History',
      route: routes.supportHistory,
      Icon: ListAlt,
    },
    {
      name: 'Gift Cards',
      route: routes.giftCard,
      Icon: Redeem,
    },
    {
      name: 'Offers',
      route: routes.offersLD,
      Icon: LocalOffer,
    },
    {
      name: 'Loyalty',
      route: routes.ctgLoyalty,
      Icon: CrownIcon,
    },
    {
      name: 'Challenges',
      route: routes.challenges,
      Icon: Stars,
    },
    {
      name: 'Stores',
      route: routes.store,
      Icon: Store,
    },
  ],
  Logo: BKLogo,
  theme: {
    brandColor: BK_BBQ_BROWN,
  },
  ctgLoyaltyEnabled: true,
  hasPhysicalCtgLoyaltyCard: false,
  loyaltyPointsIssuanceRange: {
    USA: {
      min: 1,
      max: 1500,
      step: 1,
    },
    CAN: {
      min: 1,
      max: 15_000,
      step: 1,
    },
    DEFAULT: {
      min: 1,
      max: 1500,
      step: 1,
    },
  },
  loyaltyPointsPerCurrency: {
    USA: 100,
    CAN: 100,
    DEFAULT: 100,
  },
  allowPointsOnLoyaltyTab: {
    USA: true,
    CAN: true,
    DEFAULT: true,
  },
};

export default bkConfigs;
